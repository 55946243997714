<template>
	<div>
        <ValidationObserver ref="sciForm">
        <b-row>
            <div class="col-12 col-md-9 col-lg-9 col-xl-9">
                <ValidationProvider name="course" rules="required" v-slot="{valid, errors}">
                    <b-form-group>
                        <course-auto-complete
                            v-model="course"
                            resultType="code"
                            :validate-error="errors[0]"
                        ></course-auto-complete>
                    </b-form-group>
                </ValidationProvider>
            </div>
            <div class="col-12 col-md-3 col-lg-3 col-xl-3">
                <b-button @click="saveCourse" variant="primary" block>{{$t('add')}}</b-button>
            </div>
        </b-row>
            <b-row>
                <div class="col-12">
                    <hr>
                </div>
                <div class="col-12">
                    <b-table
                        :empty-text="this.$t('there_are_no_records')"
                        bordered
                        hover
                        striped
                        responsive
                        :items="courses"
                        :fields="coursesFields"
                        show-empty
                        class="mb-5 table-dropdown no-scrollbar border rounded">
                    </b-table>
                </div>
            </b-row>
        </ValidationObserver>
    </div>
</template>

<script>
	// Components
    import CourseAutoComplete from "@/components/interactive-fields/CourseAutoComplete"

	// Services
    import GraduateApplicationService from "@/services/GraduateApplicationService";

	// Other
	import {ValidationProvider, ValidationObserver} from "vee-validate"
    import qs from "qs";

	export default {
		components: {
            CourseAutoComplete,

			ValidationProvider,
			ValidationObserver,
		},
		props: {
            row: {
                type: Object
            },
            graduateApplicationId: {
                type: Number
            },
            nationalId: {
                type: String
            },
            pin: {
                type: String
            }
		},
		data() {
			return {
                course: null,

                courses: [],
                coursesFields: [
                    {
                        key: 'code',
                        label: this.$t('course_code'),
                    },
                    {
                        key: 'name',
                        label: this.$t('course_name'),
                    },
                ],
			}
		},
		watch: {
            row: function(val){
                this.getCourses()
            }
		},
		created() {

		},
		methods: {
            getCourses(){
                this.courses = []
                let config = {
                    national_id: this.nationalId,
                    pin: this.pin
                }
                GraduateApplicationService.show(config).then(response => {
                    let data = response.data.data
                    data.preferences.forEach(itm => {
                        if(itm.id == this.row.perferenceId) {
                            if(itm.scientific_preparation_courses) {
                                Object.keys(itm.scientific_preparation_courses).forEach((item) => {
                                    this.courses.push({
                                        code: item,
                                        name: itm.scientific_preparation_courses[item].name
                                    })
                                })
                            }
                        }
                    })
                }).catch(e => {
                    if (e.status == '404' || e.status == '406') {
                        if (e.data.message) {
                            this.$toast.error(this.$t('api.'+e.data.message));
                        }
                    }
                })
            },

            async saveCourse(){
                const isValid = await this.$refs.sciForm.validate();
                if (isValid) {
                    let formData = {
                        graduate_application_id: this.graduateApplicationId,
                        preference_id: this.row.perferenceId,
                        course_code: this.course
                    }

                    GraduateApplicationService.scientificPreparationCourseSave(formData).then(response => {
                        this.$toast.success(this.$t('api.'+response.data.message));
                        this.course = null
                        this.$refs.sciForm.reset()
                        this.getCourses()
                    }).catch(e => {
                        if (e.status == '404' || e.status == '406') {
                            if (e.data.message) {
                                this.$toast.error(this.$t('api.'+e.data.message));
                            }
                        } else {
                            if (e.data.errors.course_code) {
                                this.$refs.sciForm.errors.course.push(e.data.errors.course_code[0]);
                            }
                        }
                    })
                }
            }
        }
	}
</script>
