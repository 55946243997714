<template>
	<div>
        <div class="row">
            <div class="col-12">
                <b-tabs content-class="py-5 position-relative" class="mt-5" v-model="tabIndex">
                    <div class="tabs-bottom-line"></div>
                    <b-tab>
                        <template #title><i class="ri-profile-line top-plus-2 mr-2"></i>{{ $t('personal_information') }}</template>
                        <ValidationObserver ref="personalInfoForm">
                            <div class="row">
                                <div class="col-12 col-sm-4 col-md-4">
                                    <ValidationProvider name="nationalId" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('national_id')">
                                            <b-form-input
                                                :disabled="true"
                                                :placeholder="$t('national_id_placeholder')"
                                                v-mask="'###########'"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                v-model="personalInfoForm.nationalId"></b-form-input>
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4">
                                    <ValidationProvider name="name" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('name')">
                                            <b-form-input
                                                :disabled="true"
                                                :placeholder="$t('name_placeholder')"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                v-model="personalInfoForm.name"></b-form-input>
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4">
                                    <ValidationProvider name="surname" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('surname')">
                                            <b-form-input
                                                :disabled="true"
                                                :placeholder="$t('surname_placeholder')"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                v-model="personalInfoForm.surname"></b-form-input>
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4">
                                    <ValidationProvider name="gender" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('gender')">
                                            <gender-selectbox
                                                :disabled="true"
                                                v-model="personalInfoForm.gender"
                                                :validateError="errors[0]"></gender-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4">
                                    <ValidationProvider name="birthdate" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('date_of_birth')">
                                            <b-form-input
                                                :disabled="true"
                                                type="date"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                v-model="personalInfoForm.birthdate"></b-form-input>
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4">
                                    <ValidationProvider name="nationalityCode" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('nationality')">
                                            <country-selectbox
                                                valueType="code"
                                                :validateError="errors[0]"
                                                v-model="personalInfoForm.nationalityCode">
                                            </country-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4">
                                    <ValidationProvider name="militaryStatus" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('military_status')">
                                            <parameter-selectbox v-model="personalInfoForm.militaryStatus"
                                                                 code="military_statuses"
                                                                 :validate-error="errors[0]"></parameter-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4">
                                    <ValidationProvider name="email" rules="required|email" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('email')">
                                            <b-form-input
                                                :placeholder="$t('email_placeholder')"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                v-model="personalInfoForm.email"></b-form-input>
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4">
                                    <ValidationProvider name="phone" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('mobile_number')">
                                            <b-form-input
                                                :placeholder="$t('mobile_placeholder')"
                                                v-mask="'(+49)-##-########'"
                                                placeholder="(+49)-##-########"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                v-model="personalInfoForm.phone"></b-form-input>
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4">
                                    <ValidationProvider name="applicationType" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('application_type')">
                                            <parameter-selectbox v-model="personalInfoForm.applicationType"
                                                                 code="graduate_application_types"
                                                                 :validate-error="errors[0]"></parameter-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 mt-4">
                                    <b-button @click="savePersonalInfo" type="button" variant="primary" >{{ $t('save') }}</b-button>
                                </div>
                            </div>
                            <div class="row mb2">
                                <div class="d-flex justify-content-between">

                                </div>
                            </div>
                        </ValidationObserver>
                    </b-tab>
                    <b-tab>
                        <template #title><i class="ri-profile-line top-plus-2 mr-2"></i>{{ $t('program_select') }}</template>
                        <div class="row">
                            <div class="col-12 col-sm-8 col-md-8">
                                <b-form-group :label="$t('program')">
                                    <program-groups-selectbox
                                        v-model="programFilter.department">
                                    </program-groups-selectbox>
                                </b-form-group>
                            </div>
                            <div class="col-12 col-sm-4 col-md-4">
                                <b-form-group :label="$t('program_level')">
                                    <program-level-selectbox
                                        v-model="programFilter.level">
                                    </program-level-selectbox>
                                </b-form-group>
                            </div>
                            <div class="col-12 col-sm-4 col-md-4">
                                <b-form-group :label="$t('with_without_thesis')">
                                    <thesis-with-true-false-selectbox
                                        v-model="programFilter.withThesis">
                                    </thesis-with-true-false-selectbox>
                                </b-form-group>
                            </div>
                            <div class="col-12 col-sm-4 col-md-4">
                                <b-form-group :label="$t('language')">
                                    <language-selectbox
                                        v-model="programFilter.language">
                                    </language-selectbox>
                                </b-form-group>
                            </div>
                            <div class="col-12 col-sm-4 col-md-4">
                                <b-form-group :label="$t('program_days')">
                                    <program-days-selectbox
                                        v-model="programFilter.programDays">
                                    </program-days-selectbox>
                                </b-form-group>
                            </div>
                            <div class="col-12 mt-4">
                                <h6>{{$t('programs_to_choose')}}</h6>
                            </div>
                            <div class="col-12">
                                <b-table
                                    :empty-text="this.$t('program_information')"
                                    bordered
                                    hover
                                    striped
                                    responsive
                                    :items="availablePrograms"
                                    :fields="availableProgramsFields"
                                    show-empty
                                    class="mb-5 table-dropdown no-scrollbar border rounded">
                                    <template #cell(buttons)="row">
                                        <div>
                                            <b-button @click="sendToSelecteds(row)" variant="primary" size="sm">{{$t('select_btn')}}</b-button>
                                        </div>
                                    </template>
                                </b-table>
                            </div>
                            <div class="col-12 mt-2">
                                <hr/>
                            </div>
                            <div class="col-12 mt-4">
                                <h6>{{$t('programs_selected')}}</h6>
                            </div>
                            <div class="col-12">
                                <b-table
                                    :empty-text="this.$t('there_are_no_records')"
                                    bordered
                                    hover
                                    striped
                                    responsive
                                    :items="selectedPrograms"
                                    :fields="selectedProgramsFields"
                                    show-empty
                                    class="mb-5 table-dropdown no-scrollbar border rounded">
                                    <template #cell(buttons)="row">
                                        <b-dropdown variant="link btn-sm" boundary="window" no-caret>
                                            <template #button-content>
                                                <i class="ri-more-fill"></i>
                                            </template>
                                            <div id="dropdownListHead">
                                                <b-dropdown-item @click="showPreferencesStatusModal(row)">
                                                    <i class="ri-edit-box-line align-middle top-minus-1 mr-3 text-muted"></i> {{$t('status_update')}}
                                                </b-dropdown-item>
                                                <b-dropdown-item @click="showScientificPreparationModal(row)">
                                                    <i class="ri-play-list-add-line align-middle top-minus-1 mr-3 text-muted"></i> {{$t('scientific_preparation_courses')}}
                                                </b-dropdown-item>
                                                <b-dropdown-item @click="showScholarshipsModal(row)">
                                                    <i class="ri-folder-chart-2-line align-middle top-minus-1 mr-3 text-muted"></i> {{$t('scholarships')}}
                                                </b-dropdown-item>
                                                <b-dropdown-item @click="showStudentStatusModal(row)">
                                                    <i class="ri-folder-user-line align-middle top-minus-1 mr-3 text-muted"></i> {{$t('student_status_btn')}}
                                                </b-dropdown-item>
                                                <b-dropdown-item @click="showCourseTransferModal(row)">
                                                    <i class="ri-folder-shared-line align-middle top-minus-1 mr-3 text-muted"></i> {{$t('course_transfer')}}
                                                </b-dropdown-item>
                                                <b-dropdown-item @click="sendToAvailables(row)">
                                                    <i class="ri-delete-bin-line align-middle top-minus-1 mr-3 text-muted"></i> {{$t('delete')}}
                                                </b-dropdown-item>
                                            </div>
                                        </b-dropdown>
                                    </template>
                                </b-table>
                            </div>
                            <div class="col-12 mt-4">
                                <b-button @click="savePrograms" type="button" variant="primary" >{{ $t('save') }}</b-button>
                            </div>
                        </div>
                    </b-tab>
                    <b-tab>
                        <template #title><i class="ri-profile-line top-plus-2 mr-2"></i>{{ $t('job_and_contact_info') }}</template>
                        <ValidationObserver ref="jobContactForm">
                            <div class="row" v-if="tabIndex == 2">
                                <div class="col-12">
                                    <h6>{{$t('job_info')}}</h6>
                                </div>
                                <div class="col-12">
                                    <hr/>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4">
                                    <ValidationProvider name="sector" rules="" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('job_sector')">
                                            <parameter-selectbox v-model="jobContactForm.sector"
                                                                 code="graduate_application_sectors"
                                                                 :validate-error="errors[0]"></parameter-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4">
                                    <ValidationProvider name="corporation" rules="" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('job_corporation')">
                                            <b-form-input
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                v-model="jobContactForm.corporation"></b-form-input>
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4">
                                    <ValidationProvider name="job" rules="" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('job')">
                                            <b-form-input
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                v-model="jobContactForm.job"></b-form-input>
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4">
                                    <ValidationProvider name="jobDepartment" rules="" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('job_department')">
                                            <b-form-input
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                v-model="jobContactForm.jobDepartment"></b-form-input>
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4">
                                    <ValidationProvider name="title" rules="" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('title1')">
                                            <parameter-selectbox v-model="jobContactForm.title"
                                                                 code="graduate_application_work_titles"
                                                                 :validate-error="errors[0]"></parameter-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4">
                                    <ValidationProvider name="experience" rules="" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('job_experience')">
                                            <parameter-selectbox v-model="jobContactForm.experience"
                                                                 code="graduate_application_work_experiences"
                                                                 :description="$t('job_experience_desc')"
                                                                 :validate-error="errors[0]"></parameter-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12">
                                    <h6>{{$t('contact_information')}}</h6>
                                </div>
                                <div class="col-12">
                                    <hr/>
                                </div>
                                <div class="col-12 mb-2">
                                    <h6>{{$t('home_address')}}</h6>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4">
                                    <ValidationProvider name="neighborhood" rules="" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('neighborhood')">
                                            <b-form-input
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                v-model="jobContactForm.neighborhood"></b-form-input>
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4">
                                    <ValidationProvider name="street" rules="" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('street')">
                                            <b-form-input
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                v-model="jobContactForm.street"></b-form-input>
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4">
                                    <ValidationProvider name="streetNo" rules="" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('street_and_no')">
                                            <b-form-input
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                v-model="jobContactForm.streetNo"></b-form-input>
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4">
                                    <ValidationProvider name="city" rules="" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('city')">
                                            <city-selectbox
                                                :validateError="errors[0]"
                                                v-model="jobContactForm.city">
                                            </city-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4">
                                    <ValidationProvider name="district" rules="" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('district')">
                                            <district-outline-selectbox
                                                :city_id="jobContactForm.city"
                                                :validateError="errors[0]"
                                                v-model="jobContactForm.district">
                                            </district-outline-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4">
                                    <div class="row">
                                        <div class="col-4 col-sm-4 col-md-4">
                                            <ValidationProvider name="homeTelCode" rules="" v-slot="{valid, errors}">
                                                <b-form-group :label="$t('code')">
                                                    <b-form-input
                                                        v-mask="'###'"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        v-model="jobContactForm.homeTelCode"></b-form-input>
                                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-8 col-sm-8 col-md-8">
                                            <ValidationProvider name="homeTel" rules="" v-slot="{valid, errors}">
                                                <b-form-group :label="$t('home_tel')">
                                                    <b-form-input
                                                        v-mask="'#######'"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        v-model="jobContactForm.homeTel"></b-form-input>
                                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 mb-2">
                                    <h6>{{$t('job_address')}}</h6>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4">
                                    <ValidationProvider name="neighborhoodJob" rules="" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('neighborhood')">
                                            <b-form-input
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                v-model="jobContactForm.neighborhoodJob"></b-form-input>
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4">
                                    <ValidationProvider name="streetJob" rules="" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('street')">
                                            <b-form-input
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                v-model="jobContactForm.streetJob"></b-form-input>
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4">
                                    <ValidationProvider name="streetNoJob" rules="" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('street_and_no')">
                                            <b-form-input
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                v-model="jobContactForm.streetNoJob"></b-form-input>
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4">
                                    <ValidationProvider name="cityJob" rules="" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('city')">
                                            <city-selectbox
                                                :validateError="errors[0]"
                                                v-model="jobContactForm.cityJob">
                                            </city-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4">
                                    <ValidationProvider name="districtJob" rules="" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('district')">
                                            <district-outline-selectbox
                                                :city_id="jobContactForm.cityJob"
                                                :validateError="errors[0]"
                                                v-model="jobContactForm.districtJob">
                                            </district-outline-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4">
                                    <div class="row">
                                        <div class="col-4 col-sm-4 col-md-4">
                                            <ValidationProvider name="jobTelCode" rules="" v-slot="{valid, errors}">
                                                <b-form-group :label="$t('code')">
                                                    <b-form-input
                                                        v-mask="'###'"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        v-model="jobContactForm.jobTelCode"></b-form-input>
                                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-8 col-sm-8 col-md-8">
                                            <ValidationProvider name="jobTel" rules="" v-slot="{valid, errors}">
                                                <b-form-group :label="$t('job_tel')">
                                                    <b-form-input
                                                        v-mask="'#######'"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        v-model="jobContactForm.jobTel"></b-form-input>
                                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 mt-4">
                                    <b-button @click="saveJobContact" type="button" variant="primary" >{{ $t('save') }}</b-button>
                                </div>
                            </div>
                        </ValidationObserver>
                    </b-tab>
                    <b-tab>
                        <template #title><i class="ri-profile-line top-plus-2 mr-2"></i>{{ $t('education_and_exam_info') }}</template>
                        <ValidationObserver ref="educationExamForm">
                            <div class="row" v-if="tabIndex == 3">
                                <div class="col-12">
                                    <h6>{{$t('education_info')}}</h6>
                                </div>
                                <div class="col-12">
                                    <hr/>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4">
                                    <ValidationProvider name="graduationDegree" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('graduation_degree')">
                                            <parameter-selectbox v-model="educationExamForm.graduationDegree"
                                                                 code="graduate_application_graduation_degrees"
                                                                 :validate-error="errors[0]"></parameter-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-8 col-md-8">
                                    <ValidationProvider name="university" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('graduation_university')">
                                            <university-selectbox
                                                v-model="educationExamForm.university"
                                                :validate-error="errors[0]"></university-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4">
                                    <ValidationProvider name="faculty" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('faculty')">
                                            <b-form-input
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                v-model="educationExamForm.faculty"></b-form-input>
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4">
                                    <ValidationProvider name="department" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('department')">
                                            <b-form-input
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                v-model="educationExamForm.department"></b-form-input>
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4">
                                    <ValidationProvider name="year" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('graduation_year')">
                                            <years-selectbox
                                                :interval="40"
                                                v-model="educationExamForm.year"
                                                :validate-error="errors[0]"></years-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4">
                                    <ValidationProvider name="average" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('graduation_average')">
                                            <b-form-input
                                                type="number"
                                                step="0.01"
                                                min="0.00"
                                                :max="educationExamForm.averageSystem == 'four_system' ? 4 : 100"
                                                v-check-min-max-value
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                v-model="educationExamForm.average"></b-form-input>
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-6 col-md-6">
                                    <b-form-group v-slot="{ ariaDescribedby }">
                                        <label class="mt-5"> </label>
                                        <ValidationProvider name="averageSystem" rules="required" v-slot="{valid, errors}">
                                            <b-form-radio-group v-model="educationExamForm.averageSystem" :aria-describedby="ariaDescribedby">
                                                <b-form-radio value="four_system">{{ $t('average_system_4') }}</b-form-radio>
                                                <b-form-radio value="hundred_system">{{ $t('average_system_100') }}</b-form-radio>
                                            </b-form-radio-group>
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </ValidationProvider>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-sm-2 col-md-2 d-flex justify-content-end mt-4">
                                    <b-button @click="addToEducations" type="button" variant="primary" >{{ $t('add') }}</b-button>
                                </div>
                                <div class="col-12">
                                    <b-table
                                        :empty-text="this.$t('there_are_no_records')"
                                        bordered
                                        hover
                                        striped
                                        responsive
                                        :items="selectedEducations"
                                        :fields="selectedEducationsFields"
                                        show-empty
                                        class="mb-5 table-dropdown no-scrollbar border rounded">
                                        <template #cell(button)="row">
                                            <div>
                                                <b-button @click="deleteFromSelectedEducations(row)" variant="danger" size="sm" :title="$t('delete')"><i class="ri-delete-bin-line"></i></b-button>
                                            </div>
                                        </template>
                                    </b-table>
                                </div>
                            </div>
                        </ValidationObserver>
                        <ValidationObserver ref="examForm">
                            <div class="row">
                                <div class="col-12">
                                    <h6>{{$t('exam_info')}}</h6>
                                </div>
                                <div class="col-12">
                                    <hr/>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4">
                                    <ValidationProvider name="exam" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('exam')">
                                            <parameter-selectbox
                                                v-model="examForm.exam"
                                                code="graduate_application_exams"
                                                :validate-error="errors[0]"></parameter-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4">
                                    <ValidationProvider name="date" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('exam_date')">
                                            <v-date-picker
                                                v-model="examForm.date"
                                                locale="en"
                                                is-expanded
                                                :popover="{ 'visibility': 'click' }">
                                                <template
                                                    v-slot="{ inputValue, inputEvents }">
                                                    <b-input-group>
                                                        <b-form-input
                                                            :readonly="true"
                                                            :value="inputValue"
                                                            v-on="inputEvents"
                                                            :state="errors[0] ? false : null"
                                                        ></b-form-input>
                                                        <b-input-group-append>
                                                            <b-button
                                                                variant="outline-secondary"
                                                                class="btn-40"
                                                                disabled
                                                            >
                                                                <i class="ri-calendar-line"></i
                                                                ></b-button>
                                                        </b-input-group-append>
                                                        <b-form-invalid-feedback v-if="errors[0]"
                                                                                 v-html="errors[0]"></b-form-invalid-feedback>
                                                    </b-input-group>
                                                </template>
                                            </v-date-picker>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-2 col-md-2">
                                    <ValidationProvider name="score" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('points')">
                                            <b-form-input
                                                type="number"
                                                step="1"
                                                min="0"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                v-model="examForm.score"></b-form-input>
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-2 col-md-2 d-flex justify-content-end mt-5">
                                    <b-button @click="addToExams" type="button" variant="primary" >{{ $t('add') }}</b-button>
                                </div>
                                <div class="col-12">
                                    <b-table
                                        :empty-text="this.$t('there_are_no_records')"
                                        bordered
                                        hover
                                        striped
                                        responsive
                                        :items="selectedExams"
                                        :fields="selectedExamsFields"
                                        show-empty
                                        class="mb-5 table-dropdown no-scrollbar border rounded">
                                        <template #cell(button)="row">
                                            <div>
                                                <b-button @click="deleteFromSelectedExams(row)" variant="danger" size="sm" :title="$t('delete')"><i class="ri-delete-bin-line"></i></b-button>
                                            </div>
                                        </template>
                                    </b-table>
                                </div>
                            </div>
                        </ValidationObserver>
                        <ValidationObserver ref="bauExamForm">
                            <div v-if="bauExamShow" class="row">
                                <div class="col-12 col-sm-12 col-md-12">
                                    <ValidationProvider name="bauExam" :rules="bauExamShow ? 'required' : ''" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('bau_language_exam_question')">
                                            <yes-no-selectbox
                                                :description="$t('bau_language_exam_info')"
                                                v-model="bauExamForm.bauExam"
                                                :validate-error="errors[0]"></yes-no-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 mt-4">
                                    <b-button @click="saveEducationExam" type="button" variant="primary" >{{ $t('save') }}</b-button>
                                </div>
                            </div>
                        </ValidationObserver>
                    </b-tab>
                    <b-tab>
                        <template #title><i class="ri-profile-line top-plus-2 mr-2"></i>{{ $t('documents') }}</template>
                        <ValidationObserver ref="documentsForm">
                            <div class="row" v-if="tabIndex == 4">
                                <div class="col-12 mb-2">
                                    <p>{{$t('ph_documents_info')}}</p>
                                    <p>{{$t('graduate_application_yok_statement')}}</p>
                                </div>
                                <div class="col-12" v-for="(file,index) in requiredDocuments" v-bind:key="index">
                                    <b-row no-gutters class="file-upload-box mb-1">
                                        <b-col sm="12" md="5" lg="4" v-b-popover.hover.bottom="$t('cancel_reason')+': '+file.description" v-if="file.status == 'denied'">
                                            <div class="filename d-flex" :class="{'active': file.status != 'waiting' ? true : false}">
                                                    <span class="flex-grow-1">
                                                        <i v-if="file.description" class="ri-information-fill"></i>
                                                        {{
                                                            ['thesis_project_documents','graduate_transcript','post_graduate_diploma'].includes(index) ? '(*) '+file.name : file.name
                                                        }}</span>
                                            </div>
                                        </b-col>
                                        <b-col sm="12" md="5" lg="4" v-if="file.status != 'denied'">
                                            <div class="filename d-flex" :class="{'active': index == 'other_documents' ? otherDocuments.length > 0 ? true : false : file.status != 'waiting' ? true : false}">
                                                    <span class="flex-grow-1">
                                                        <i v-if="file.description" class="ri-information-fill"></i>
                                                        {{
                                                            ['thesis_project_documents','graduate_transcript','post_graduate_diploma'].includes(index) ? '(*) '+file.name : file.name
                                                        }}</span>
                                            </div>
                                        </b-col>
                                        <b-col sm="12" md="4" lg="5">
                                            <b-input-group>
                                                <b-form-file
                                                    :ref="index"
                                                    v-model="files[index]"
                                                    :placeholder="file.file_name!=null?file.file_name:$t('select_file')+'...'"
                                                >
                                                    <b-form-file id="file-small"></b-form-file>
                                                </b-form-file>
                                                <b-input-group-append>
                                                    <b-button variant="outline-secondary" class="btn-40 download"
                                                              :v-b-popover.hover.bottom="$t('download')"
                                                              v-if="file.status != 'waiting' ? true : false"
                                                              @click="downloadFile(index, file.file_name,0)">
                                                        <i class="ri-download-2-line"></i>
                                                    </b-button>
                                                    <b-button variant="danger" class="btn-40"
                                                              :v-b-popover.hover.bottom="$t('delete')"
                                                              v-if="file.status != 'waiting' ? true : false"
                                                              @click="deleteFile(index,0)">
                                                        <i class="ri-delete-bin-line"></i>
                                                    </b-button>
                                                    <b-button variant="outline-secondary" class="btn-40"
                                                              :v-b-popover.hover.bottom="$t('upload')"
                                                              v-if="['waiting','denied'].includes(file.status)"
                                                              @click="upload(index)">
                                                        <i class="ri-add-fill"></i>
                                                    </b-button>
                                                </b-input-group-append>
                                            </b-input-group>
                                        </b-col>
                                        <b-col sm="12" md="3" lg="3" v-if="index != 'other_documents'">
                                                <b-form-group>
                                                    <parameter-selectbox v-model="fileStatus[index]"
                                                                         code="graduate_application_document_status"
                                                                         @input="changeDocumentStatus(index, 0)"
                                                                         ></parameter-selectbox>
                                                </b-form-group>
                                        </b-col>
                                    </b-row>
                                </div>
                                <div class="col-12" v-if="Object.keys(requiredDocuments).length > 8">
                                    {{$t('ph_programs_info')}}
                                </div>
                                <div class="col-12" v-if="otherDocuments.length > 0">
                                    <div class="border pt-3 mb-5 rounded">
                                        <div class="col-12" v-for="(file,index) in otherDocuments" v-bind:key="index">
                                            <b-row no-gutters class="file-upload-box mb-1">
                                                <b-col sm="12" md="4" lg="4">
                                                    <div class="filename d-flex" :class="{'active': file.status != 'waiting' ? true : false}">
                                                        <span class="flex-grow-1">{{$t('other_documents')}}</span>
                                                    </div>
                                                </b-col>
                                                <b-col sm="12" md="5" lg="5">
                                                    <b-input-group>
                                                        <b-form-file
                                                            :ref="index"
                                                            v-model="files[index]"
                                                            :placeholder="file.file_name!=null?file.file_name:$t('select_file')+'...'"
                                                            :disabled="true"
                                                        >
                                                            <b-form-file id="file-small"></b-form-file>
                                                        </b-form-file>
                                                        <b-input-group-append>
                                                            <b-button variant="outline-secondary" class="btn-40 download"
                                                                      :v-b-popover.hover.right="$t('download')"
                                                                      v-if="file.status != 'waiting' ? true : false"
                                                                      @click="downloadFile(index, file.file_name, file.id)">
                                                                <i class="ri-download-2-line"></i>
                                                            </b-button>
                                                            <b-button variant="danger" class="btn-40"
                                                                      :v-b-popover.hover.right="$t('delete')"
                                                                      v-if="file.status != 'waiting' ? true : false"
                                                                      @click="deleteFile(index, file.id)">
                                                                <i class="ri-delete-bin-line"></i>
                                                            </b-button>
                                                        </b-input-group-append>
                                                    </b-input-group>
                                                </b-col>
                                                <b-col sm="12" md="3" lg="3">
                                                    <b-form-group>
                                                        <parameter-selectbox v-model="otherDocumentsStatus[index]"
                                                                             code="graduate_application_document_status"
                                                                             @input="changeDocumentStatus(index, file.id)"
                                                        ></parameter-selectbox>
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 mt-4">
                                    <b-button @click="saveDocuments" type="button" variant="primary" >{{ $t('save') }}</b-button>
                                </div>
                            </div>
                        </ValidationObserver>
                    </b-tab>
                </b-tabs>
            </div>
        </div>
        <CommonModal ref="preferenceStatusModel" size="sm">
            <template v-slot:CommonModalTitle>
                <div>{{$t('status_update')}}</div>
            </template>
            <template v-slot:CommonModalContent>
                <div>
                    <status-update
                        @close="hidePreferencesStstusModel"
                        :row="rowClass"
                        :graduateApplicationId="graduateApplicationId"
                        :pin="pin"></status-update>
                </div>
            </template>
        </CommonModal>
        <CommonModal ref="scientificPreparationModal" size="lg">
            <template v-slot:CommonModalTitle>
                <div>{{$t('scientific_preparation_courses')}}</div>
            </template>
            <template v-slot:CommonModalContent>
                <div>
                    <scientific-preparation
                        :row="rowClass"
                        :graduateApplicationId="graduateApplicationId"
                        :nationalId="nationalId"
                        :pin="pin"></scientific-preparation>
                </div>
            </template>
        </CommonModal>
        <CommonModal ref="scholarshipsModal" size="lg">
            <template v-slot:CommonModalTitle>
                <div>{{$t('scholarships')}}</div>
            </template>
            <template v-slot:CommonModalContent>
                <div>
                    <scholarships
                        :row="rowClass"
                        :graduateApplicationId="graduateApplicationId"
                        :nationalId="nationalId"
                        :pin="pin"></scholarships>
                </div>
            </template>
        </CommonModal>
        <CommonModal ref="studentStatusModal" size="md">
            <template v-slot:CommonModalTitle>
                <div>{{$t('student_status_btn')}}</div>
            </template>
            <template v-slot:CommonModalContent>
                <div>
                    <student-status
                        @close="hideStudentStstusModal"
                        :row="rowClass"
                        :nationalId="nationalId"
                        :graduateApplicationId="graduateApplicationId"
                        :pin="pin"></student-status>
                </div>
            </template>
        </CommonModal>
        <CommonModal ref="descriptionModal" size="md">
            <template v-slot:CommonModalTitle>
                <div>{{$t('cancel_reason')}}</div>
            </template>
            <template v-slot:CommonModalContent>
                <div>
                    <ValidationObserver ref="descriptionForm">
                        <ValidationProvider name="description" rules="required" v-slot="{valid, errors}">
                            <b-form-group>
                                <b-form-textarea v-model="description"
                                                 :class="errors[0] ? 'is-invalid':''"
                                                 rows="5"
                                />
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                            </b-form-group>
                        </ValidationProvider>
                    </ValidationObserver>
                    <b-button @click="changeToDenied" type="button" variant="primary" >{{ $t('save') }}</b-button>
                </div>
            </template>
        </CommonModal>
        <CommonModal ref="courseTransfersModal" size="lg">
            <template v-slot:CommonModalTitle>
                <div>{{$t('course_transfer')}}</div>
            </template>
            <template v-slot:CommonModalContent>
                <div>
                    <course-transfers
                        :row="rowClass"
                        :graduateApplicationId="graduateApplicationId"></course-transfers>
                </div>
            </template>
        </CommonModal>
    </div>
</template>

<script>
	// Components
    import GenderSelectbox from "@/components/interactive-fields/GenderSelectbox";
    import CountrySelectbox from "@/components/interactive-fields/CountrySelectbox";
    import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox"
    import ProgramGroupsSelectbox from "@/components/interactive-fields/ProgramGroupsSelectbox";
    import ProgramLevelSelectbox from "@/components/interactive-fields/ProgramLevelSelectbox";
    import ThesisWithTrueFalseSelectbox from "@/components/interactive-fields/ThesisWithTrueFalseSelectbox";
    import LanguageSelectbox from "@/components/interactive-fields/LanguageSelectbox"
    import ProgramDaysSelectbox from "@/components/interactive-fields/ProgramDaysSelectbox";
    import CommonModal from "@/components/elements/CommonModal";
    import CitySelectbox from "@/components/interactive-fields/CitySelectbox"
    import DistrictOutlineSelectbox from "@/components/interactive-fields/DistrictOutlineSelectbox"
    import UniversitySelectbox from "@/components/interactive-fields/UniversitySelectbox"
    import YearsSelectbox from "@/components/interactive-fields/YearsSelectbox";
    import YesNoSelectbox from "@/components/interactive-fields/YesNoSelectbox"
    //Pages
    import StatusUpdate from "./UpdateForm/StatusUpdate";
    import ScientificPreparation from "./UpdateForm/ScientificPreparation";
    import Scholarships from "./UpdateForm/Scholarships";
    import StudentStatus from "./UpdateForm/StudentStatus";
    import CourseTransfers from "./UpdateForm/CourseTransfers";
	// Services
    import GraduateApplicationService from "@/services/GraduateApplicationService";
    import ProgramService from "@/services/ProgramService";

	// Other
	import {ValidationProvider, ValidationObserver} from "vee-validate"
    import qs from "qs";
    import moment from "moment";
    import documentData from "../data/Documents";

	export default {
		components: {
            GenderSelectbox,
            CountrySelectbox,
            ParameterSelectbox,
            ProgramGroupsSelectbox,
            ProgramLevelSelectbox,
            ThesisWithTrueFalseSelectbox,
            LanguageSelectbox,
            ProgramDaysSelectbox,
            CommonModal,
            CitySelectbox,
            DistrictOutlineSelectbox,
            UniversitySelectbox,
            YearsSelectbox,
            YesNoSelectbox,

            StatusUpdate,
            ScientificPreparation,
            Scholarships,
            StudentStatus,
            CourseTransfers,

			ValidationProvider,
			ValidationObserver,
		},
		props: {
			nationalId: {
				type: String,
			},
            pin: {
                type: String,
            }
		},
		data() {
			return {
                tabIndex: 0,
                graduateApplicationId: null,
                rowClass: {},

                personalInfoForm: {
                    nationalId: null,
                    name: null,
                    surname: null,
                    gender: null,
                    birthdate: null,
                    nationalityCode: null,
                    militaryStatus: null,
                    email: null,
                    phone: null,
                    applicationType: null,
                },

                programFilter: {
                    department: null,
                    level: null,
                    withThesis: null,
                    language: null,
                    programDays: null
                },

                availablePrograms: [],
                availableProgramsFields: [
                    {
                        key: 'code',
                        label: this.$t('program_code'),
                    },
                    {
                        key: 'name',
                        label: this.$t('program_name'),
                    },
                    {
                        key: 'language',
                        label: this.$t('program_language'),
                    },
                    {
                        key: 'with_thesis',
                        label: this.$t('with_without_thesis'),
                    },
                    {
                        key: 'buttons',
                        label: '',
                    },
                ],

                selectedPrograms: [],
                selectedProgramsFields: [
                    {
                        key: 'buttons',
                        label: ''
                    },
                    {
                        key: 'code',
                        label: this.$t('program_code'),
                    },
                    {
                        key: 'name',
                        label: this.$t('program_name'),
                    },
                    {
                        key: 'status_name',
                        label: this.$t('status'),
                    }
                ],

                jobContactForm: {
                    sector: null,
                    corporation: null,
                    job: null,
                    jobDepartment: null,
                    title: null,
                    experience: null,
                    neighborhood: null,
                    street: null,
                    streetNo: null,
                    city: null,
                    district: null,
                    homeTel: null,
                    homeTelCode: null,
                    neighborhoodJob: null,
                    streetJob: null,
                    streetNoJob: null,
                    cityJob: null,
                    districtJob: null,
                    jobTel: null,
                    jobTelCode: null,
                },

                educationExamForm: {
                    graduationDegree: null,
                    university: null,
                    faculty: null,
                    department: null,
                    year: null,
                    average: null,
                    averageSystem: null
                },

                examForm: {
                    exam: null,
                    date: null,
                    score: null,
                    bauExam: null
                },

                selectedEducations: [],
                selectedEducationsFields: [
                    {
                        key: 'university_name',
                        label: this.$t('university'),
                    },
                    {
                        key: 'graduation_degree',
                        label: this.$t('graduation_degree'),
                    },
                    {
                        key: 'faculty',
                        label: this.$t('faculty'),
                    },
                    {
                        key: 'department',
                        label: this.$t('department'),
                    },
                    {
                        key: 'year',
                        label: this.$t('graduation_year'),
                    },
                    {
                        key: 'graduation_system',
                        label: this.$t('system_name'),
                    },
                    {
                        key: 'average',
                        label: this.$t('graduation_average'),
                    },
                    {
                        key: 'button',
                        label: '',
                    },
                ],

                selectedExams: [],
                selectedExamsFields: [
                    {
                        key: 'name',
                        label: this.$t('exam'),
                    },
                    {
                        key: 'date',
                        label: this.$t('exam_date'),
                    },
                    {
                        key: 'score',
                        label: this.$t('score'),
                    },
                    {
                        key: 'button',
                        label: '',
                    },
                ],

                bauExamForm: {
                    bauExam: null
                },

                bauExamShow: false,

                requiredDocuments: [],
                files: [],
                fileStatus: [],
                description: null,
                changeFileType: null,
                otherDocuments: [],
                otherDocumentsStatus: [],
                changeOtherFileId: 0,

			}
		},
		watch: {
            programFilter : {
                handler(val) {
                    const counter = []
                    Object.keys(val).some(elem => {
                        if(val[elem] != null){
                            counter.push(elem)
                        }
                    })
                    this.availablePrograms = []
                    if(counter.length > 0) {
                        this.getAvailablePrograms()
                    }
                },
                deep: true
            },

            tabIndex: {
                handler: function (val) {
                    if(this.tabIndex == 1) {
                        this.getProgramPerferences()
                    } else if(this.tabIndex == 3) {
                        this.getExams()
                        this.getEducations()
                    } else if(this.tabIndex == 4) {
                        this.getRequiredDocuments()
                    }
                }
            }
		},
		created() {
            if(this.nationalId && this.pin){
                this.get(this.nationalId, this.pin)
            }
		},
		methods: {
            get(nationalId, pin){
                let config = {
                    national_id: nationalId,
                    pin: pin
                }
                GraduateApplicationService.show(config).then(response => {
                    let data = response.data.data
                    this.graduateApplicationId = data.id
                    //Personal Info
                    this.personalInfoForm.nationalId = data.national_id
                    this.personalInfoForm.name = data.name
                    this.personalInfoForm.surname = data.surname
                    this.personalInfoForm.gender = data.gender
                    this.personalInfoForm.birthdate = data.birthdate
                    this.personalInfoForm.nationalityCode = data.nationality_code
                    this.personalInfoForm.militaryStatus = data.military_status
                    this.personalInfoForm.email = data.email
                    this.personalInfoForm.phone = data.phone
                    this.personalInfoForm.applicationType = data.application_type
                    //Job/Contact Form
                    this.jobContactForm.sector = data.sector
                    this.jobContactForm.corporation = data.institution_working
                    this.jobContactForm.job = data.job
                    this.jobContactForm.jobDepartment = data.working_unit
                    this.jobContactForm.title = data.title
                    this.jobContactForm.experience = data.work_experience
                    this.jobContactForm.neighborhood = data.neighbourhood
                    this.jobContactForm.street = data.street
                    this.jobContactForm.streetNo = data.address
                    this.jobContactForm.city = data.province != null ? parseInt(data.province) : null
                    this.jobContactForm.district = data.district
                    this.jobContactForm.homeTel = data.home_phone != null ? data.home_phone.slice(3) : null
                    this.jobContactForm.homeTelCode = data.home_phone != null ? data.home_phone.slice(0,3) : null
                    this.jobContactForm.neighborhoodJob = data.work_neighbourhood
                    this.jobContactForm.streetJob = data.work_street
                    this.jobContactForm.streetNoJob = data.work_address
                    this.jobContactForm.cityJob = data.work_province != null ? parseInt(data.work_province) : null
                    this.jobContactForm.districtJob = data.work_district
                    this.jobContactForm.jobTel = data.work_phone != null ? data.work_phone.slice(3) : null
                    this.jobContactForm.jobTelCode = data.work_phone != null ? data.work_phone.slice(0,3) : null
                    //Other Documents
                    if(data.otherDocuments){
                        this.otherDocuments = data.otherDocuments
                        data.otherDocuments.forEach((itm, index) => {
                            this.otherDocumentsStatus[index] = itm.status
                        })
                    }
                }).catch(e => {
                    if (e.status == '404' || e.status == '406') {
                        if (e.data.message) {
                            this.$toast.error(this.$t('api.' + e.data.message));
                        }
                    }
                })
            },

            async savePersonalInfo(){
                if(this.checkPermission('graduateapplication_save')){
                    const isValid = await this.$refs.personalInfoForm.validate();

                    if(isValid){
                        let formData = {
                            email: this.personalInfoForm.email,
                            national_id: this.nationalId,
                            pin: this.pin,
                            nationality_code: this.personalInfoForm.nationalityCode,
                            military_status: this.personalInfoForm.militaryStatus,
                            application_type: this.personalInfoForm.applicationType,
                            phone: this.personalInfoForm.phone
                        }

                        GraduateApplicationService.saveAll(formData).then(response => {
                            this.$toast.success(this.$t('api.' + response.data.message))
                            this.tabIndex++
                        }).catch(e => {
                            if (e.status == '404' || e.status == '406') {
                                if (e.data.message) {
                                    this.$toast.error(this.$t('api.'+e.data.message))
                                }
                            }
                        })

                    }
                } else {
                    this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                }
            },

            getAvailablePrograms(){
                let filter = {}
                this.programFilter.department != null ? filter.group_id = this.programFilter.department : false
                this.programFilter.level != null ? filter.level = this.programFilter.level : false
                this.programFilter.withThesis != null ? filter.with_thesis = this.programFilter.withThesis : false
                this.programFilter.language != null ? filter.language = this.programFilter.language : false
                this.programFilter.programDays != null ? filter.program_days = this.programFilter.programDays : false

                let config = {
                    params: {
                        filter: filter,
                        limit: -1
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                }

                ProgramService.graduateGetAllPublic(config).then(response => {
                    let selecteds = []
                    this.selectedPrograms.forEach(itm => {
                        selecteds.push(itm.code)
                    })

                    response.data.data.forEach((itm, index) => {
                        response.data.data[index].name = itm.name
                        response.data.data[index].with_thesis = itm.with_thesis == true ? this.$t('with_thesis') : this.$t('without_thesis')
                    })
                    this.availablePrograms = response.data.data.filter(c => !selecteds.includes(c.code))
                }).catch(e => {
                    console.error(e)
                })
            },

            getProgramPerferences(){
                this.selectedPrograms = []
                let formData = {
                    graduate_application_id: this.graduateApplicationId,
                    pin: this.pin
                }
                GraduateApplicationService.getProgramPerferences(formData).then(response => {
                    response.data.data.forEach((itm) => {
                        this.selectedPrograms.push({
                            code: itm.program.code,
                            name: itm.program.name,
                            perferenceId: itm.id,
                            status_name: itm.status_name,
                            status: itm.status
                        })
                    })
                })
            },

            sendToAvailables(record){
                if(this.checkPermission('graduateapplicationpreference_delete')){
                    let formData = {
                        params: {
                            graduate_application_id: this.graduateApplicationId,
                            pin: this.pin
                        },
                        paramsSerializer: (params) => qs.stringify(params, {encode: false}),

                    }

                    GraduateApplicationService.deleteProgramPerference(record.item.perferenceId, formData).then(response => {
                        this.$toast.success(this.$t('api.'+response.data.message))
                        this.getProgramPerferences()
                    }).catch(e => {
                        if (e.status == '404' || e.status == '406') {
                            if (e.data.message) {
                                this.$toast.error(this.$t('api.'+e.data.message));
                            }
                        }
                    })
                } else {
                    this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                }
            },

            sendToSelecteds(record){
                if(this.checkPermission('graduateapplicationpreference_store')){
                    let check = this.selectedPrograms.some(elem => {
                        if(elem.code === record.item.code){
                            return true
                        }
                        return false
                    })
                    if(!check){
                        let formData = {
                            graduate_application_id: this.graduateApplicationId,
                            pin: this.pin,
                            faculty_code: 34,
                            program_code: record.item.code,
                            //Kaldırılacak
                            point_type: 1,
                            order_of_success: 1,
                            rank: 1,
                            class: 1
                        }
                        GraduateApplicationService.saveProgramPerference(formData).then(response => {
                            this.getProgramPerferences()
                            this.getAvailablePrograms()
                            this.$toast.success(this.$t('api.'+response.data.message))
                        }).catch(e => {
                            if (e.status == '404' || e.status == '406') {
                                if (e.data.message) {
                                    this.$toast.error(this.$t('api.'+e.data.message));
                                }
                            }
                        })
                    } else {
                        this.$swal
                            .fire({
                                text: this.$t('program_selected'),
                                icon: 'info',
                                showCancelButton: false,
                                confirmButtonText: this.$t('ok'),
                            })
                    }
                } else {
                    this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                }
            },

            savePrograms(){
                if(this.checkPermission('graduateapplicationpreference_save')){
                    let formData = {
                        graduate_application_id: this.graduateApplicationId,
                        pin: this.pin
                    }
                    GraduateApplicationService.preferanceSave(formData).then(response => {
                        this.tabIndex++
                    }).catch(e => {
                        if (e.status == '404' || e.status == '406') {
                            if (e.data.message) {
                                this.$toast.error(this.$t('api.'+e.data.message));
                            }
                        }
                    })
                } else {
                    this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                }
            },

            showPreferencesStatusModal(row){
                this.$refs.preferenceStatusModel.$refs.commonModal.show()
                this.rowClass = row.item
            },

            hidePreferencesStstusModel(){
                this.$refs.preferenceStatusModel.$refs.commonModal.hide()
                this.getProgramPerferences()
            },

            showScientificPreparationModal(row){
                this.$refs.scientificPreparationModal.$refs.commonModal.show()
                this.rowClass = row.item
            },

            showScholarshipsModal(row){
                this.$refs.scholarshipsModal.$refs.commonModal.show()
                this.rowClass = row.item
            },

            showStudentStatusModal(row){
                this.$refs.studentStatusModal.$refs.commonModal.show()
                this.rowClass = row.item
            },

            hideStudentStstusModal(){
                this.$refs.studentStatusModal.$refs.commonModal.hide()
            },

            showCourseTransferModal(row){
                this.$refs.courseTransfersModal.$refs.commonModal.show()
                this.rowClass = row.item
            },

            async saveJobContact(){
                if(this.checkPermission('graduateapplication_contactinformation')){
                    const isValid = await this.$refs.jobContactForm.validate();
                    if (isValid) {
                        let formData = {
                            national_id: this.nationalId,
                            pin: this.pin,
                            email: this.personalInfoForm.email,
                            birthplace: 'kaldırılacak',
                            sector: this.jobContactForm.sector,
                            institution_working: this.jobContactForm.corporation,
                            job: this.jobContactForm.job,
                            working_unit: this.jobContactForm.jobDepartment,
                            title: this.jobContactForm.title,
                            work_experience: this.jobContactForm.experience,
                            neighbourhood: this.jobContactForm.neighborhood,
                            street: this.jobContactForm.street,
                            address: this.jobContactForm.streetNo,
                            province: this.jobContactForm.city,
                            district: this.jobContactForm.district,
                            home_phone: this.jobContactForm.homeTelCode+this.jobContactForm.homeTel
                        }
                        this.jobContactForm.neighborhoodJob != null ? formData.work_neighbourhood = this.jobContactForm.neighborhoodJob : false
                        this.jobContactForm.streetJob != null ? formData.work_street = this.jobContactForm.streetJob : false
                        this.jobContactForm.streetNoJob != null ? formData.work_address = this.jobContactForm.streetNoJob : false
                        this.jobContactForm.cityJob != null ? formData.work_province = this.jobContactForm.cityJob : false
                        this.jobContactForm.districtJob != null ? formData.work_district = this.jobContactForm.districtJob : false
                        this.jobContactForm.jobTel != null ? formData.work_phone = this.jobContactForm.jobTelCode+this.jobContactForm.jobTel : false

                        GraduateApplicationService.contactInfoSave(formData).then(response => {
                            this.$toast.success(this.$t('api.'+response.data.message));
                            this.tabIndex++
                        }).catch(e => {
                            if (e.status == '404' || e.status == '406') {
                                if (e.data.message) {
                                    this.$toast.error(this.$t('api.'+e.data.message));
                                }
                            } else {
                                if (e.data.errors.sector) {
                                    this.$refs.jobContactForm.errors.sector.push(e.data.errors.sector[0]);
                                }
                                if (e.data.errors.institution_working) {
                                    this.$refs.jobContactForm.errors.corporation.push(e.data.errors.institution_working[0]);
                                }
                                if (e.data.errors.job) {
                                    this.$refs.jobContactForm.errors.job.push(e.data.errors.job[0]);
                                }
                                if (e.data.errors.working_unit) {
                                    this.$refs.jobContactForm.errors.jobDepartment.push(e.data.errors.working_unit[0]);
                                }
                                if (e.data.errors.title) {
                                    this.$refs.jobContactForm.errors.title.push(e.data.errors.title[0]);
                                }
                                if (e.data.errors.work_experience) {
                                    this.$refs.jobContactForm.errors.experience.push(e.data.errors.work_experience[0]);
                                }
                                if (e.data.errors.neighborhood) {
                                    this.$refs.jobContactForm.errors.neighborhood.push(e.data.errors.neighborhood[0]);
                                }
                                if (e.data.errors.street) {
                                    this.$refs.jobContactForm.errors.street.push(e.data.errors.street[0]);
                                }
                                if (e.data.errors.address) {
                                    this.$refs.jobContactForm.errors.streetNo.push(e.data.errors.address[0]);
                                }
                                if (e.data.errors.province) {
                                    this.$refs.jobContactForm.errors.province.push(e.data.errors.province[0]);
                                }
                                if (e.data.errors.district) {
                                    this.$refs.jobContactForm.errors.district.push(e.data.errors.district[0]);
                                }
                                if (e.data.errors.home_phone) {
                                    this.$refs.jobContactForm.errors.homeTel.push(e.data.errors.home_phone[0]);
                                }
                                if (e.data.errors.work_neighborhood) {
                                    this.$refs.jobContactForm.errors.neighborhoodJob.push(e.data.errors.work_neighborhood[0]);
                                }
                                if (e.data.errors.work_street) {
                                    this.$refs.jobContactForm.errors.streetJob.push(e.data.errors.work_street[0]);
                                }
                                if (e.data.errors.work_address) {
                                    this.$refs.jobContactForm.errors.streetNoJob.push(e.data.errors.work_address[0]);
                                }
                                if (e.data.errors.work_province) {
                                    this.$refs.jobContactForm.errors.cityJob.push(e.data.errors.work_province[0]);
                                }
                                if (e.data.errors.work_district) {
                                    this.$refs.jobContactForm.errors.districtJob.push(e.data.errors.work_district[0]);
                                }
                                if (e.data.errors.work_phone) {
                                    this.$refs.jobContactForm.errors.jobTel.push(e.data.errors.work_phone[0]);
                                }
                            }
                        })

                    }
                } else {
                    this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                }
            },

            async addToEducations(){
                if(this.checkPermission('graduateapplicationeducation_store')){
                    const isValid = await this.$refs.educationExamForm.validate();
                    if (isValid) {
                        let formData = {
                            graduate_application_id: this.graduateApplicationId,
                            pin: this.pin,
                            graduation_degree: this.educationExamForm.graduationDegree,
                            university_id: this.educationExamForm.university,
                            faculty: this.educationExamForm.faculty,
                            program: this.educationExamForm.department,
                            graduation_year: this.educationExamForm.year,
                            gpa: this.educationExamForm.average,
                            graduation_system: this.educationExamForm.averageSystem
                        }

                        GraduateApplicationService.saveEducation(formData).then(response => {
                            this.getEducations()
                            this.educationExamForm = {}
                            this.$refs.educationExamForm.reset();
                            this.$toast.success(this.$t('api.' + response.data.message));
                        }).catch(e => {
                            this.$toast.error(this.$t('api.' + e.data.message));
                        })

                    }
                } else {
                    this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                }
            },

            getEducations(){
                let config = {
                    national_id: this.nationalId,
                    pin: this.pin
                }

                this.selectedEducations = []
                GraduateApplicationService.show(config).then(response => {
                    response.data.data.educations.forEach((index) => {
                        this.selectedEducations.push({
                            id: index.id,
                            graduationDegree: index.graduation_degree,
                            university: index.university_id,
                            university_name: index.university.name,
                            graduation_degree: index.graduation_degree_name,
                            graduation_system: index.graduation_system_name,
                            faculty: index.faculty,
                            department: index.program,
                            year: index.graduation_year,
                            average: index.gpa,
                            averageSystem: index.graduation_system

                        })
                    })
                }).catch(e => {
                    this.$toast.error(this.$t('api.'+e.data.message))
                })
            },

            getExams() {
                let config = {
                    national_id: this.nationalId,
                    pin: this.pin
                }
                this.selectedExams = []
                GraduateApplicationService.show(config).then(response => {
                    this.serviceData = response.data.data
                    this.serviceData.preferences.forEach(itm => {
                        if(itm.level == 'YL') {
                            this.bauExamShow = true
                        }
                    })
                    this.bauExamForm.bauExam = this.serviceData.proficiency_status
                    if(this.serviceData.exams != null){
                        Object.keys(this.serviceData.exams).forEach(index => {
                            this.selectedExams.push({
                                exam: this.serviceData.exams[index].code,
                                name: this.serviceData.exams[index].name,
                                date: this.serviceData.exams[index].date,
                                score: this.serviceData.exams[index].score
                            })
                        })
                    }
                }).catch(e => {
                    this.$toast.error(this.$t('api.' + e.data.message))
                })
            },

            deleteFromSelectedEducations(record){
                if(this.checkPermission('graduateapplicationeducation_delete')){
                    this.$swal({
                        text: this.$t('education_delete_info'),
                        showCancelButton: true,
                        confirmButtonText: this.$t('yes'),
                        cancelButtonText: this.$t('no')
                    }).then(response => {
                        if (response.isConfirmed == true) {
                            let formData = {
                                params: {
                                    graduate_application_id: this.graduateApplicationId,
                                    pin: this.pin
                                },
                                paramsSerializer: (params) => qs.stringify(params, {encode: false}),

                            }
                            GraduateApplicationService.deleteEducation(record.item.id, formData).then(response => {
                                this.getEducations()
                                this.$toast.success(this.$t('api.' + response.data.message));
                            }).catch(e => {
                                this.$toast.error(this.$t('api.' + e.data.message));
                            })

                        }
                    })
                } else {
                    this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                }
            },

            async addToExams(){
                if(this.checkPermission('graduateapplicationexam_store')){
                    const isValid = await this.$refs.examForm.validate();
                    if (isValid) {
                        let formData = {
                            graduate_application_id: this.graduateApplicationId,
                            pin: this.pin,
                            graduate_exam_code: this.examForm.exam,
                            date: moment(this.examForm.date).format('YYYY-MM-DD'),
                            score: this.examForm.score
                        }

                        GraduateApplicationService.saveExam(formData).then(response => {
                            this.getExams()
                            this.examForm = {}
                            this.$refs.examForm.reset();
                            this.$toast.success(this.$t('api.' + response.data.message));
                        }).catch(e => {
                            this.$toast.error(this.$t('api.' + e.data.message));
                        })

                    }
                } else {
                    this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                }
            },

            deleteFromSelectedExams(record){
                if(this.checkPermission('graduateapplicationexam_delete')){
                    this.$swal({
                        text: this.$t('exam_delete_info'),
                        showCancelButton: true,
                        confirmButtonText: this.$t('yes'),
                        cancelButtonText: this.$t('no')
                    }).then(response => {
                        if (response.isConfirmed == true) {
                            let formData = {
                                params: {
                                    graduate_application_id: this.graduateApplicationId,
                                    graduate_exam_code: record.item.exam,
                                    pin: this.pin
                                },
                                paramsSerializer: (params) => qs.stringify(params, {encode: false}),

                            }

                            GraduateApplicationService.deleteExam(formData).then(response => {
                                this.getExams()
                                this.$toast.success(this.$t('api.' + response.data.message));
                            }).catch(e => {
                                this.$toast.error(this.$t('api.' + e.data.message))
                            })
                        }
                    })
                } else {
                    this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                }
            },

            async saveEducationExam(){
                if(this.checkPermission('graduateapplicationeducation_save')){
                    const isValid = await this.$refs.bauExamForm.validate();
                    if (isValid) {
                        let formData = {
                            address: this.jobContactForm.address,
                            email: this.personalInfoForm.email,
                            national_id: this.nationalId,
                            pin: this.pin,
                            sector: this.jobContactForm.sector,
                            proficiency_status: this.bauExamForm.bauExam
                        }
                        GraduateApplicationService.saveAll(formData).then(response => {
                            let eduData = {
                                graduate_application_id: this.graduateApplicationId,
                                pin: this.pin
                            }
                            GraduateApplicationService.educationSave(eduData).then(response => {
                                this.$toast.success(this.$t('api.' + response.data.message));
                                this.tabIndex++
                            }).catch(e => {
                                if (e.status == 406) {
                                    this.$toast.error(this.$t('api.' + e.data.message));
                                }
                            })
                        }).catch(e => {
                            this.$toast.error(this.$t('api.' + e.data.message))
                        })
                    }
                } else {
                    this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                }
            },

            getRequiredDocuments(){
                if(this.checkPermission('graduateapplication_requireddocuments')){
                    let formData = {
                        graduate_application_id: this.graduateApplicationId,
                        pin: this.pin
                    }
                    GraduateApplicationService.requiredDocuments(formData).then(response => {
                        this.requiredDocuments = response.data.data
                        Object.keys(this.requiredDocuments).forEach(index => {
                            this.fileStatus[index] = this.requiredDocuments[index].status
                        })
                    }).catch(e => {
                        this.$toast.error(this.$t('api.' + e.data.message))
                    })
                } else {
                    this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                }
            },

            upload(index){
                if(this.checkPermission('graduateapplication_upload')){
                    //setTimeout(() => {
                    let formData = new FormData()
                    formData.append('graduate_application_id', this.graduateApplicationId)
                    formData.append('pin', this.pin)
                    formData.append('type', index)
                    formData.append('file', this.files[index])

                    GraduateApplicationService.uploadDocument(formData).then(response => {
                        this.$toast.success(this.$t('api.' + response.data.message))
                        this.getRequiredDocuments()
                        this.get(this.nationalId, this.pin)
                    }).catch(e => {
                        if (e.status == 422) {
                            this.$toast.error(e.data.errors.file[0]);
                        }
                        if (e.status == 406) {
                            this.$toast.error(this.$t('api.' + e.data.message));
                        }
                    })
                    //},2000)
                } else {
                    this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                }
            },

            deleteFile(index, otherDocumentId){
                if(this.checkPermission('graduateapplication_deletefile')){
                    let params = {
                        graduate_application_id: this.graduateApplicationId,
                        pin: this.pin,
                        type: index
                    }
                    if(otherDocumentId != 0){
                        params.type = 'other_documents'
                        params.other_document_id = otherDocumentId
                    }
                    let formData = {
                        params: params,

                        paramsSerializer: (params) => qs.stringify(params, {encode: false})
                    }

                    GraduateApplicationService.deleteFile(formData).then(response => {
                        this.$toast.success(this.$t('api.' + response.data.message))
                        this.getRequiredDocuments()
                        this.get(this.nationalId, this.pin)
                    }).catch(e => {
                        if (e.status == 406) {
                            this.$toast.error(this.$t('api.' + e.data.message));
                        }
                    })
                } else {
                    this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                }
            },

            downloadFile(type, fileName, otherDocumentId){
                if(this.checkPermission('graduateapplication_documentdownload')){
                    let document = {
                        type: type
                    }
                    if(otherDocumentId != 0){
                        document.other_document_id = otherDocumentId
                        document.type = 'other_documents'
                    }

                    GraduateApplicationService.downloadDocument(this.graduateApplicationId, document).then(response => {
                        let obj = {
                            graduateApplicationId: this.graduateApplicationId,
                            document: document
                        }
                        if(response.headers["content-type"].indexOf('pdf') > 0){
                            documentData(obj, 'document').callback()
                        } else {
                            this._downloadFile(response, fileName)
                        }

                    }).catch(e => {
                        this.$toast.error(this.$t('api.' + e.data.message))
                    })
                } else {
                    this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                }
            },

            saveDocuments(){
                this.$emit('close',true)
            },

            changeDocumentStatus(type, otherDocumentId){
                if(this.checkPermission('graduateapplication_approvedocument')){
                    if(this.fileStatus[type] == 'denied' || this.otherDocumentsStatus[type] == 'denied') {
                        this.$refs.descriptionModal.$refs.commonModal.show()
                        this.changeFileType = type
                        this.changeOtherFileId = otherDocumentId
                    } else {
                        let formData = {}
                        if(otherDocumentId == 0){
                            formData = {
                                status: this.fileStatus[type],
                                type: type
                            }
                        } else {
                            formData = {
                                status: this.otherDocumentsStatus[type],
                                type: 'other_documents',
                                other_document_id: otherDocumentId
                            }
                        }

                        GraduateApplicationService.changeDocumentStatus(this.graduateApplicationId,formData).then(response => {
                            this.$toast.success(this.$t('api.' + response.data.message))
                            this.getRequiredDocuments()
                            this.get(this.nationalId, this.pin)
                        }).catch(e => {
                            if (e.status == 406) {
                                this.$toast.error(this.$t('api.' + e.data.message));
                            }
                        })
                    }
                } else {
                    this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                }
            },

            async changeToDenied(){
                const isValid = await this.$refs.descriptionForm.validate();
                if (isValid) {
                    let formData = {
                        status: this.fileStatus[this.changeFileType],
                        type: this.changeFileType,
                        description: this.description
                    }
                    if(this.changeOtherFileId != 0){
                        formData.other_document_id = this.changeOtherFileId
                        formData.status = this.otherDocumentsStatus[this.changeFileType]
                        formData.type = 'other_documents'
                    }
                    GraduateApplicationService.changeDocumentStatus(this.graduateApplicationId,formData).then(response => {
                        this.$toast.success(this.$t('api.' + response.data.message))
                        this.getRequiredDocuments()
                        this.get(this.nationalId, this.pin)
                        this.description = null
                        this.changeFileType = null
                        this.changeOtherFileId = 0
                        this.$refs.descriptionModal.$refs.commonModal.hide()
                    }).catch(e => {
                        if (e.status == 406) {
                            this.$toast.error(this.$t('api.' + e.data.message));
                        }
                    })
                }
            }

		}
	}
</script>
<style>
.rowClass {
    cursor: all-scroll;
}
</style>
