<template>
	<div>
		<app-layout>
			<template v-slot:header>
				<Header :title="$t('graduate_applications')"
				        :isNewButton="false"
				        :isColumns="true"
                        :actions="subMenu"
				        @filter-div-status="datatable.filterStatus=$event"
				>
					<template v-slot:columns>
						<div class="mb-1" v-for="(column,key) in datatable.columns">
							<b-form-checkbox
								:id="'checkbox-'+key"
								:name="'checkbox-'+key"
								:value="false"
								:unchecked-value="true"
								v-model="column.hidden"
								v-if="column.field!='buttons'"
							>
								{{ column.label }}
							</b-form-checkbox>
						</div>
					</template>
				</Header>
			</template>
			<template v-slot:header-mobile>
				<HeaderMobile :title="$t('graduate_applications')"
				              :isNewButton="false"
				              :isColumns="true"
				              @filter-div-status="datatable.filterStatus=$event"
				>
					<template v-slot:columns>
						<div class="mb-1" v-for="(column,key) in datatable.columns">
							<b-form-checkbox
								:id="'checkbox-'+key"
								:name="'checkbox-'+key"
								:value="false"
								:unchecked-value="true"
								v-model="column.hidden"
								v-if="column.field!='buttons'"
							>
								{{ column.label }}
							</b-form-checkbox>
						</div>
					</template>
				</HeaderMobile>
			</template>
			<datatable-filter
                v-show="datatable.filterStatus"
                @filter="filter"
                @filterClear="filterClear"
                :exportExcel="checkPermission('graduateapplication_excelexport')"
                @exportExcel="exportExcel"
                :otherButton="{ show: true, title:'LogExcel',emit:'logExcel'}"
                @logExcel="logExcel"
              >
                <b-tabs content-class="py-5 position-relative" class="line-tabs">
                    <div class="tabs-bottom-line"></div>
                    <b-tab active>
                        <template #title>
                            <span>{{$t('personal_info')}}</span>
                        </template>
                        <b-row>
                            <b-col sm="6" md="3">
                                <b-form-group :label="$t('national_id')">
                                    <b-form-input type="text" v-model="datatable.queryParams.filter.national_id"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col sm="6" md="3">
                                <b-form-group :label="$t('name')">
                                    <b-form-input type="text" v-model="datatable.queryParams.filter.name"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col sm="6" md="3">
                                <b-form-group :label="$t('surname')">
                                    <b-form-input type="text" v-model="datatable.queryParams.filter.surname"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col sm="6" md="3">
                                <b-form-group :label="$t('nationality')">
                                    <country-selectbox
                                        valueType="code"
                                        v-model="datatable.queryParams.filter.nationality_code">
                                    </country-selectbox>
                                </b-form-group>
                            </b-col>

                        </b-row>
                    </b-tab>
                    <b-tab>
                        <template #title>
                            <span>{{$t('program_info')}}</span>
                        </template>

                        <b-row>
                            <b-col sm="6" md="6">
                                <b-form-group :label="$t('date')">
                                    <date-between
                                        v-model="datatable.queryParams.filter.date_between"></date-between>
                                </b-form-group>
                            </b-col>
                            <b-col sm="6" md="3">
                                <b-form-group :label="$t('academic_year')+'/'+$t('semester')">
                                    <semesters-selectbox v-model="datatable.queryParams.filter.semester_id"></semesters-selectbox>
                                </b-form-group>
                            </b-col>
                            <b-col sm="6" md="3">
                                <b-form-group :label="$t('program_level')">
                                    <program-level-selectbox v-model="datatable.queryParams.filter.program_level"></program-level-selectbox>
                                </b-form-group>
                            </b-col>

                            <b-col sm="6" md="4">
                                <b-form-group :label="$t('faculty')">
                                    <faculty-selectbox v-model="datatable.queryParams.filter.faculty_code"></faculty-selectbox>
                                </b-form-group>
                            </b-col>
                            <b-col sm="6" md="4">
                                <b-form-group :label="$t('department')">
                                    <department-selectbox
                                        :faculty_code="datatable.queryParams.filter.faculty_code"
                                        v-model="datatable.queryParams.filter.department_code"></department-selectbox>
                                </b-form-group>
                            </b-col>

                            <b-col sm="6" md="4">
                                <b-form-group :label="$t('program')">
                                    <program-selectbox
                                        :is_department_code_required="true"
                                        :faculty_code="datatable.queryParams.filter.faculty_code"
                                        :department_code="datatable.queryParams.filter.department_code"
                                        v-model="datatable.queryParams.filter.program_code"></program-selectbox>
                                </b-form-group>
                            </b-col>
                        </b-row>

                    </b-tab>
                    <b-tab>
                        <template #title>
                            <span>{{$t('application_information')}}</span>
                        </template>
                        <b-row>
                            <b-col sm="6" md="3">
                                <b-form-group :label="$t('pin_code')">
                                    <b-form-input type="text" v-model="datatable.queryParams.filter.pin"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col sm="6" md="3">
                                <b-form-group :label="$t('accepttance_status')">
                                    <parameter-selectbox
                                        code="graduate_application_preference_status"
                                        v-model="datatable.queryParams.filter.preference_status"></parameter-selectbox>
                                </b-form-group>
                            </b-col>
                            <b-col sm="6" md="3">
                                <b-form-group :label="$t('application_completion_status')">
                                    <parameter-selectbox
                                        code="graduate_application_completion_status"
                                        v-model="datatable.queryParams.filter.application_completion_status"></parameter-selectbox>
                                </b-form-group>
                            </b-col>

                            <b-col sm="6" md="3">
                                <b-form-group :label="$t('document_status')">
                                    <parameter-selectbox
                                        code="graduate_application_document_status"
                                        v-model="datatable.queryParams.filter.document_status"></parameter-selectbox>
                                </b-form-group>
                            </b-col>
                            <b-col sm="6" md="3">
                                <b-form-group :label="$t('application_type')">
                                    <parameter-selectbox
                                        code="graduate_application_types"
                                        v-model="datatable.queryParams.filter.application_type"></parameter-selectbox>
                                </b-form-group>
                            </b-col>
                            <b-col sm="6" md="3">
                                <b-form-group :label="$t('proficiency_status_title')">
                                    <yes-no-selectbox
                                        v-model="datatable.queryParams.filter.proficiency_status"></yes-no-selectbox>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-tab>
                </b-tabs>

			</datatable-filter>
			<datatable :isLoading.sync="datatable.isLoading"
			           :columns="datatable.columns"
			           :rows="datatable.rows"
			           :total="datatable.total"
			           :queryParams="datatable.queryParams"
			           @on-page-change="onPageChange"
			           @on-sort-change="onSortChange"
			           @on-per-page-change="onPerPageChange"
			           v-show="datatable.showTable"
			>
			</datatable>
            <CommonModal ref="updateFormModal" size="xl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
                <template v-slot:CommonModalTitle>
                    {{ $t('edit') }}
                </template>
                <template v-slot:CommonModalContent>
                    <update-form :nationalId="nationalIdForUpdate"
                                 :pin="pinForUpdate"
                                 @close="updateFormSuccess"
                                 v-if="formProcess=='update'"/>
                </template>
            </CommonModal>
            <CommonModal ref="meetingsModal" size="xl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
                <template v-slot:CommonModalTitle>
                    {{ $t('meetings') }}
                </template>
                <template v-slot:CommonModalContent>
                    <meetings-form :graduateApplicationId="graduateApplicationId"
                                 v-if="formProcess=='meetings'"/>
                </template>
            </CommonModal>

		</app-layout>
	</div>
</template>
<script>
	// Template
	import AppLayout from "@/layouts/AppLayout"
	import Header from "@/layouts/AppLayout/Header"
	import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"

	// Components
	import DatatableFilter from "@/components/datatable/DatatableFilter";
	import Datatable from "@/components/datatable/Datatable";
	import CommonModal from "@/components/elements/CommonModal";
    import CountrySelectbox from "@/components/interactive-fields/CountrySelectbox";
    import DateBetween from "@/components/interactive-fields/DateBetween";
    import SemestersSelectbox from "@/components/interactive-fields/SemestersSelectbox"
    import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox";
    import DepartmentSelectbox from "@/components/interactive-fields/DepartmentSelectbox"
    import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox";
    import ProgramLevelSelectbox from "@/components/interactive-fields/ProgramLevelSelectbox";
    import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox"
    import YesNoSelectbox from "@/components/interactive-fields/YesNoSelectbox"

    //Pages
    import UpdateForm from "./UpdateForm"
    import MeetingsForm from "./MeetingsForm";


	// Services
	import GraduateApplicationService from "@/services/GraduateApplicationService";

	// Others
	import {ValidationProvider, ValidationObserver} from "vee-validate"
	import qs from 'qs'

	export default {
		components: {
			AppLayout,
			Header,
			HeaderMobile,

			DatatableFilter,
			Datatable,
			CommonModal,
            CountrySelectbox,
            DateBetween,
            SemestersSelectbox,
            FacultySelectbox,
            DepartmentSelectbox,
            ProgramSelectbox,
            ProgramLevelSelectbox,
            ParameterSelectbox,
            UpdateForm,
            MeetingsForm,
            YesNoSelectbox,

			ValidationProvider,
			ValidationObserver
		},
		metaInfo() {
			return {
				title: this.$t('graduate_applications')
			}
		},
		data() {
			return {
				datatable: {
					isLoading: false,
					columns: [
						{
							label: '',
							field: 'buttons',
							html: true,
							sortable: false,
							tdClass: 'p-0 text-center w-40 align-middle',
							buttons: [
								{
									text: this.$t('edit'),
									class: 'ri-edit-box-line align-middle top-minus-1 mr-3 text-muted',
                                    permission: "graduateapplication_show",
									callback: (row) => {
										this.updateFormShow(row.national_id, row.pin)
									}
								},
                                {
                                    text: this.$t('meetings'),
                                    class: 'ri-chat-quote-line align-middle top-minus-1 mr-3 text-muted',
                                    permission: "graduateapplicationmeeting_show",
                                    callback: (row) => {
                                        this.meetingsShow(row.id)
                                    }
                                },
								{
									text: this.$t('view'),
									class: 'ri-search-eye-line align-middle top-minus-1 mr-3 text-muted',
                                    permission: "graduateapplication_show",
									callback: (row) => {
										this.applicationPdf(row)
									}
								}
							]
						},
						{
							label: this.toUpperCase('id'),
							field: 'id',
							hidden: true,
						},
                        {
                            label: this.toUpperCase('national_id'),
                            field: 'national_id',
                            hidden: false,
                            sortable: false
                        },
						{
							label: this.toUpperCase('name'),
							field: 'name',
							hidden: false,
                            sortable: false
						},
						{
							label: this.toUpperCase('surname'),
							field: 'surname',
							hidden: false,
                            sortable: false
						},
                        {
                            label: this.toUpperCase('program'),
                            field: 'preferences',
                            hidden: false,
                            sortable: false
                        },
						{
							label: this.toUpperCase('application_completion_status'),
							field: ('application_completion_status_name'),
							hidden: false,
							sortable: false
						},
						{
							label: this.toUpperCase('status'),
							field: 'status',
							hidden: false,
							sortable: false
						},
                        {
                            label: this.toUpperCase('pin_code'),
                            field: 'pin',
                            hidden: true,
                        },
                        {
                            label: this.toUpperCase('proficiency_status_title'),
                            field: 'proficiency_status',
                            hidden: false,
                            formatFn: (value) => {
                                return value == 'E' ? this.$t('yes') : this.$t('no')
                            }
                        }
					],
					rows: [],
					total: 0,
					filterStatus: true,
					showTable: false,
					queryParams: {
						filter: {},
						sort: 'id',
						page: 1,
						limit: 20
					}
				},
				formProcess: null,
				nationalIdForUpdate: 0,
                pinForUpdate: 0,
                graduateApplicationId: 0,
				form: {},
                subMenu: [
                    {
                        text: this.$t('applications'),
                        class: 'ri-file-text-line',
                        permission: 'graduateapplication_index',
                        callback: () => {
                            return false;
                        }
                    },
                    {
                        text: this.$t('meetings'),
                        class: 'ri-calendar-todo-line',
                        permission: 'graduateapplicationmeeting_index',
                        callback: () => {
                            this.$router.push('/graduate/application/meetings');
                        }
                    },
                    {
                        text: this.$t('meeting_report'),
                        class: 'ri-folder-chart-line',
                        permission: 'graduateapplicationmeeting_index',
                        callback: () => {
                            this.$router.push('/graduate/application/meetings/report');
                        }
                    },
                ]
			}
		},
		created() {
			this.filterSet();
			this.formClear();
		},
		methods: {
			filterSet() {
				this.datatable.queryParams.filter = {
					national_id: null,
                    name: null,
                    surname: null,
                    nationality_code: null,
                    pin: null,
                    program_code: null,
                    faculty_code: null,
                    department_code: null,
                    date_between: null,
                    semester_id: null,
                    program_level: null,
                    document_status: null,
                    preference_status: null,
                    application_type: null,
                    application_completion_status: null,
				}
			},
			filterClear() {
				this.filterSet()
				this.getRows();
			},
			filter() {
				this.getRows();
			},
			onPageChange(params) {
				this.datatable.queryParams.page = params.currentPage;
				this.getRows();
			},
			onPerPageChange(params) {
				this.datatable.queryParams.limit = params.currentPerPage;
				this.datatable.queryParams.page = 1;
				this.getRows();
			},
			changeColumn(key) {
				this.datatable.columns[key].hidden = this.datatable.columns[key].hidden == true ? false : true;
			},
			onSortChange(params) {
				const sortType = params[0].type == 'desc' ? '-' : '';
				this.datatable.queryParams.sort = sortType + params[0].field
				this.getRows();
			},
			getRows() {
				this.datatable.showTable = true;
				this.datatable.isLoading = true;

				const config = {
					params: {
						...this.datatable.queryParams
					},
					paramsSerializer: (params) => qs.stringify(params, {encode: false})
				};

				return GraduateApplicationService.getAll(config)
				                    .then(response => {

					                    this.datatable.rows = response.data.data
					                    this.datatable.total = response.data.pagination.total

					                    this.datatable.rows.forEach((c) => {
                                            let programs = ''
                                            let statuses = ''
                                            c.preferences.forEach(itm => {
                                               programs += itm.program.code+'-'+itm.program.name+'<br>'
                                               statuses += itm.status_name+'<br>'
                                            })
                                            c.preferences = programs
                                            c.status = statuses
                                        })
				                    })
				                    .finally(() => {
					                    this.datatable.isLoading = false;
				                    });
			},
			// Clear
			formClear() {
				this.nationalIdForUpdate = null
                this.pinForUpdate = null
				this.formProcess = null
			},
            exportExcel(){
                const config = {
                    params: {
                        ...this.datatable.queryParams
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                };
                return GraduateApplicationService.excelExport(config)
                                    .then((response) => {
                                        this._downloadFile(response, this.$t('graduate_applications')+'.xlsx')
                                    })
                                    .catch(e => this.showErrors(e));
            },
            logExcel(){
                return GraduateApplicationService.exceLogExport()
                    .then((response) => {
                        this.$router.push('/job/logs/detail/'+response.data.data.log_id)
                    })
                    .catch(e => this.showErrors(e));
            },
            updateFormShow(nationalId, pin) {
                this.nationalIdForUpdate = nationalId
                this.pinForUpdate = pin
                this.formProcess = 'update'
                this.$refs.updateFormModal.$refs.commonModal.show()
            },
            updateFormSuccess() {
                this.$refs.updateFormModal.$refs.commonModal.hide()
                this.getRows()
                this.formClear()
            },
            meetingsShow(graduateApplicationId) {
                this.graduateApplicationId = graduateApplicationId
                this.formProcess = 'meetings'
                this.$refs.meetingsModal.$refs.commonModal.show()
            },

            applicationPdf(row){
                GraduateApplicationService.applicationPdf(row.id).then(response => {
                    this._downloadFile(response, row.name+'_'+row.surname+'.pdf')
                }).catch(e => {

                })
            }
		}
	}
</script>

